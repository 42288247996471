.cover {
    position: relative;
    background: $color-main;

    &__image-wrapper {
        width: calc(50% + 215px);
        height: 650px;
        margin-left: auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-white;

        & .container {
            position: relative;
            padding: 50px 15px 40px;
            margin-bottom: 50px;

            &:before {
                content: '';
                position: absolute;
                left: 15px;
                bottom: 0;
                height: 5px;
                width: 120px;
                opacity: .5;
                background-color: $color-fourth;
                border-radius: $border-radius;
            }
        }
    }

    &__title {
        color: $color-main;
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight-light;
            font-size: $font-size--5;
        }
    }

    &__intro {
        color: $color-text;
    }

    .home-slideshow__links {
        &__container {
            position: relative;
            width: 100%;
            height: 0;
            margin: 0 auto;
            left: 0;
            right: 0;
        }

        &__list {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 0;
            width: 370px;
            height: 220px;
            top: -295px;
            left: 15px;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                width: 350px;
                height: 350px;
                left: -200px;
                bottom: -325px;
                border: 5px solid $color-white;
                border-radius: $border-radius--round;
                opacity: .1;
            }
        }

        &__search {
            margin: 0;
            padding: 0;
            justify-content: flex-start;
            background: $color-bg--transparent;
            border: unset;
        }

        &__item {
            display: flex;
            align-items: center;
            width: fit-content;
            font-size: 1.375rem;
            line-height: 1.875rem;
            color: $color-white;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            & svg {
                width: 40px;
                height: 40px;
                margin-right: 15px;
                fill: $color-main;
                background-color: $color-second;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;

                &:nth-child(2) {
                    width: 25px;
                    height: 25px;
                    margin-left: 10px;
                    margin-right: 0;
                    fill: $color-second;
                    background-color: $color-bg--transparent;
                }
            }

            &:nth-child(2) {
                margin: 50px 0;
            }

            &:hover, &:focus {
                color: $color-white;

                & svg {
                    fill: $color-second;
                    background-color: $color-main;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {

        &__image-wrapper {
            height: 614px;
            width: calc(50% + 170px);
        }

        .home-slideshow__links {

            &__list {
                width: 300px;
                height: 220px;
                top: -330px;
                left: 10px;

                &:before {
                    width: 275px;
                    height: 275px;
                    left: -155px;
                    bottom: -255px;
                }
            }

            &__item {
                font-size: 1.25rem;

                &:nth-child(2) {
                    margin: 50px 0;
                }

                & svg {
                    margin-right: 10px;

                    &:nth-child(2) {
                        margin-right: 0;
                        margin-left: 2px;
                    }
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 450px;
            width: 100%;
        }

        .home-slideshow__links {

            &__container {
                position: absolute;
                top: 0;
                background-color: $color-main;
                max-width: unset;
                padding: 0;
                width: 100%;
                height: 80px;
                margin: -170px auto 90px;
            }

            &__list {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                position: unset;
                width: 100%;
                height: 80px;
                max-width: 640px;
                padding: 0 10px;
                margin: 0 auto;
                
                &:before {
                    content: none;
                }

                & button {
                    display: none;
                }
            }

            &__item {
                font-size: 1rem;
                line-height: 1.375rem;
                margin: 0 0 0 40px;

                &:nth-child(2) {
                    margin-top: 0;
                    margin-bottom: 0;
                }
 
                & svg {
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 310px;
        }

        .home-slideshow__links {
            &__list {
                max-width: 320px;
                padding: 0 25px;
                justify-content: space-between;
            }

            &__item {
                font-size: 0.75rem;
                line-height: 1rem;
                width: 110px;
                margin: 0;

                & svg {
                    min-width: 40px;
                }

                &:first-child {
                    max-width: 124px;
                }
            }
        }
    }

}
