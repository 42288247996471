.front-page {

    &__section {

        &--1 {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                margin-left: auto;
                margin-right: 0;
                height: 100%;
                width: calc(50% - 85px);
                background-color: $color-second;
            }
        }

        &--2 {
            display: flex;
            justify-content: space-between;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                width: 120px;
                height: 120px;
                bottom: -40px;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: translateX(115px);
                background-color: $color-second;
                border-radius: $border-radius--round;
            }
        }

        & .container {
            display: flex;
            justify-content: space-between;
        }
    }

    .home, section .home {
        &__title {
            display: inline-block;
            padding: 0;
            margin: 0 0 30px 0;
            font-size: 2.188rem;
            line-height: 2.375rem;
            font-weight: $font-weight-light;
            box-shadow: unset;
            text-transform: uppercase;

            &:before, &:after {
                content: none;
            }

            &__span {
                display: flex;
                font-size: 3.125rem;
                line-height: 3.438rem;
                font-weight: $font-weight-extrabold;
                text-transform: none;
                
                & svg {
                    width: 29px;
                    height: 49px;
                    margin-left: 10px;
                }
            }
        }
    }
}

//======================================================================================================
// 1 - SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    background-color: $color-main;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            pointer-events: none
        }
    }

    .swiper-container {
        margin-right: 0;
    }

    .slideshow {
        height:770px;
        width: calc(50% + 215px);

        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(11, 38, 69, 0.3) 0%, rgba(11, 38, 69, 0) 100%);
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            right: 30px;
            bottom: 50px;
            width: 500px;
            height: auto;
            padding: 20px;
            background: $color-second--rgba;
            color: $color-white;
        }

        &__title {
            margin-bottom: 15px;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
            position: absolute;
            right: 15px;
            bottom: 70px;
            height: 80px;
            width: 80px;
            pointer-events: none;
    
            &__btn {
                pointer-events: auto;
                top: 0;
                width: 40px;
                height: 80px;
                margin: 0;
                padding: 0;
                background: $color-white;
                border: 0;
    
                &::after {
                    content: none;
                }

                &:hover {
                    background-color: $color-second;
                }

                & svg {
                    width: 30px;
                    height: 30px;
                    fill: $color-main;
                }

                &--prev {
                    left: 0;
                    border-radius: 50px 0 0 50px;
                    border-right: 1px solid $color-main;

                    & svg {
                        margin-right: -10px;
                    }
                }

                &--next {
                    right: 0;
                    border-radius: 0 50px 50px 0;
                    border-left: 1px solid $color-main;

                    & svg {
                        margin-left: -10px;
                    }
                }
            }
        }    
    }

    &__links {
        &__container {
            position: relative;
            width: 100%;
            height: 0;
            margin: 0 auto;
            left: 0;
            right: 0;
        }

        &__list {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 0;
            width: 370px;
            height: 260px;
            top: -385px;
            left: 15px;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                width: 350px;
                height: 350px;
                left: -250px;
                bottom: -300px;
                border: 5px solid $color-white;
                border-radius: $border-radius--round;
                opacity: .1;
            }
        }

        &__search {
            margin: 0;
            padding: 0;
            justify-content: flex-start;
            background: $color-bg--transparent;
            border: unset;
        }

        &__item {
            display: flex;
            align-items: center;
            width: fit-content;
            font-size: 1.375rem;
            line-height: 1.875rem;
            color: $color-white;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            & svg {
                width: 40px;
                height: 40px;
                margin-right: 15px;
                fill: $color-main;
                background-color: $color-second;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;

                &:nth-child(2) {
                    width: 25px;
                    height: 25px;
                    margin-left: 10px;
                    margin-right: 0;
                    fill: $color-second;
                    background-color: $color-bg--transparent;
                }
            }

            &:nth-child(2) {
                margin: 70px 0;
            }

            &:hover, &:focus {
                color: $color-white;

                & svg {
                    fill: $color-second;
                    background-color: $color-main;
                }
            }
        }
    }
}



//======================================================================================================
// 2 - Post
//======================================================================================================
.post {
    
    &__section {
        position: relative;
        z-index: 0;
        height: 920px;
        margin-top: 80px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            bottom: 0;
            height: 570px;
            width: 100%;
            margin: 0 auto;
            background-color: $color-fourth;
        }
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 35px;

        &__btn {
            margin: 0;

            & svg {
                margin-right: 8px;
            }
        }
    }

    &__title {
        color: $color-text;
        margin: 0!important;

        & span {
            & svg {
                fill: $color-fourth;
            }
        }
    }

    &__container {
        position: relative;
        z-index: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-height: 720px;
        //gap: 30px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 170px;
            height: 170px;
            left: -85px;
            top: 140px;
            border: 5px solid $color-main;
            border-radius: $border-radius--round;
            opacity: .1;
        }

        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            width: 120px;
            height: 120px;
            right: 60px;
            top: -35px;
            background-color: $color-fourth;
            border-radius: $border-radius--round;
        }

        .featured-post {

            &--big {
                display: inline-block;
                position: relative;
                z-index: 0;
                width: 570px;
                height: 400px;
                border-radius: $border-radius;
                overflow: hidden;
                margin-bottom: 30px;

                .post {
                    &__content {
                        position: relative;
                        width: 570px;
                        height: 400px;

                        &--notimg {
                            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
                            background-position: center;
                        }

                        & img {
                            width: 100%;
                            height: auto;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(180deg, rgba(13, 32, 55, 0) 24.48%, #0D2037 92.71%);
                        }
                    }

                    &__info {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: auto;
                        padding: 0 75px 25px 25px;
                        text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8), 0px 0px 10px #000000;

                        &__title, &__introduction {
                            color: $color-white;
                        }

                        &__title {
                            font-size: 1.75rem;
                            line-height: 1.875rem;
                            margin: 0;
                            padding-bottom: 10px;
                            padding-left: 0;

                            &:before, &:after {
                               content: none; 
                            }
                        }

                        &__introduction {
                            font-size: 1rem;
                            line-height: 1.25rem;
                        }
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 5px;
                    width: 80px;
                    background-color: $color-second;
                    border-radius: 0 $border-radius $border-radius 0;
                    transition: all $duration ease-in-out;
                }

                &:hover {
                    &:after {
                        width: 100%;
                        border-radius: 0;
                    }
                }
            }
            
            &--small {
                width: 270px;

                .post {
                    &__content {
                        position: relative;
                        width: 270px;
                        height: 190px;
                        border-radius: $border-radius;
                        overflow: hidden;

                        &--notimg {
                            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-bg--image;
                            background-position: center;
                        }

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 5px;
                            width: 80px;
                            background-color: $color-second;
                            border-radius: 0 $border-radius $border-radius 0;
                            transition: all $duration ease-in-out;
                        }

                        & img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    &__info {
                        padding: 0;

                        &__title {
                            font-size: 1.375rem;
                            line-height: 1.5rem;
                            color: $color-text;
                            margin: 10px 0 0;
                            padding: 0;

                            &:before, &:after {
                                content: none;
                            }
                        }

                        &__introduction {
                            display: none;
                        }
                    }
                }

                &:hover {

                    .post__content {
                        &:after {
                            width: 100%;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}



//======================================================================================================
// 3 - Access
//======================================================================================================
.access {

    &__section {
        position: relative;
        width: 585px;
        margin-top: 80px;
        margin-bottom: 90px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            width: 170px;
            height: 170px;
            right: -170px;
            top: -165px;
            border: 5px solid $color-main;
            border-radius: $border-radius--round;
            opacity: .1;
        }
    }

    &__title {
        color: $color-text;
        & span svg {
            fill: $color-third;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 30px;
        height: 430px;
    }

    &__link {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 170px;
        height: 200px;
        padding: 10px;
        border: 5px solid rgba(126, 198, 70, .3);
        border-radius: $border-radius;
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            border-color: $color-third;
            box-shadow: 1px 1px 40px rgba(14, 53, 49, 0.1);
        }
    }

    &__info {
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: $font-weight-bold;
        padding: 0 15px 15px;
    }
}


//======================================================================================================
// 4 - Démarches
//======================================================================================================
.demarches {

    &__section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 585px;
        margin-top: 80px;
        margin-bottom: 90px;
        padding-left: 155px;
    }

    &__title {
        color: $color-text;
        margin: 0!important;

        & span svg {
            fill: $color-main;
        }
    }

    &__container {
        height:290px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__content {
        &__title {
            display: flex;
            align-items: center;
            color: $color-text;
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: $font-weight-bold;

            & svg {
                width: 30px;
                height: 30px;
                margin-right: 20px;
                fill: $color-main;
                background-color: $color-white;
                border-radius: $border-radius--round;
                transition: all $duration ease-in-out;
            }

            &:hover {
                & svg {
                    background-color: $color-second;
                }
            }
        }
    }

    &__button {
        width: fit-content;
        margin: 0;

        & svg {
            margin-right: 8px;
        }
    }
}


//======================================================================================================
// 5 - Events
//======================================================================================================
.events {

    &__section {
        position: relative;
        z-index: 1;
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 70px 30px;

        &__btn {
            margin: 0;
        
            & svg {
                margin-right: 8px;
            }
        }
    }

    &__title {
        color: $color-white;
        margin: 0!important;
        display: inline-block;
        padding: 0;
        font-size: 2.188rem;
        line-height: 2.375rem;
        font-weight: $font-weight-light;
        box-shadow: unset;
        text-transform: uppercase;

        & span {
            display: flex;
            font-size: 3.125rem;
            line-height: 3.438rem;
            font-weight: $font-weight-extrabold;
            text-transform: none;
            
            & svg {
                width: 29px;
                height: 49px;
                margin-left: 10px;
                fill: $color-second;
            }
        }


        &:before, &:after {
            content: none;
        }
    }

    &__section {
        height: 840px;
        padding-top: 80px;
        background-color: $color-main;
        
        .swiper-container {
            width: 1030px;
            overflow: unset;

            .swiper-wrapper {
                .swiper-slide {
                    width: 500px;
                    margin-right: 30px;
                    opacity: .3;
                    transition: all $duration ease-in-out;

                    &.swiper-slide-active, &.swiper-slide-next {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__navigation {
        height: 400px;
        position: absolute;
        width: 1110px;
        margin: 0 auto;
        left: 0;
        right: 0;

        &__container {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                width: 120px;
                height: 120px;
                right: 135px;
                top: -30px;
                border-radius: 50%;
                border: 5px solid $color-second;
                opacity: .1;
            }
        }

        &__btn {
            pointer-events: auto;
            top: 160px;
            width: 40px;
            height: 80px;
            margin: 0;
            padding: 0;
            background: $color-white;
            border: 0;

            &::after {
                content: none;
            }

            &:hover, &:focus {
                background-color: $color-second;

                & svg {
                    fill: $color-main;
                }
            }

            & svg {
                width: 30px;
                height: 30px;
                fill: $color-main;
            }

            &--prev {
                left: 0;
                border-radius: 50px 0 0 50px;

                & svg {
                    margin-right: -10px;
                }
            }

            &--next {
                right: 0;
                border-radius: 0 50px 50px 0;

                & svg {
                    margin-left: -10px;
                }
            }
        }
    }

    &__content {
        height: 400px;
        border-radius: $border-radius;
        overflow: hidden;
        
        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-bg--image;
            background-position: center;
        }

        & img {
            transform: scale(1);
            transition: all $duration ease-in-out;
            width: 100%;
            height: auto;
        }
    }

    &__link:hover {
        .events__content {
            & img {
                transform: scale(1.1);
            }
        }
    }

    &__infos {
        margin-top: 20px;

        &__title {
            margin: 10px 0 0;
            font-size: 1.75rem;
            line-height: 1.875rem;
            color: $color-white;
            padding-left: 0;

            &:before, &:after {
                content: none;
            }
        }
    }

    &__date {
        font-size: 1.375rem;
        line-height: 1.5rem;
        color: $color-second;
        font-family: $font-family--heading;
    }
}


//======================================================================================================
// 6 - Social
//======================================================================================================
.social {

    &__section {
        position: relative;
        z-index: 0;
        margin-top: 80px;
        margin-bottom: 90px;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            top: -220px;
            width: 285px;
            height: 285px;
            margin: 0 auto;
            transform: translateX(435px);
            border: 5px solid $color-fourth;
            border-radius: $border-radius--round;
            opacity: .3;
        }
    }

    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 35px;

        &__btn svg {
            margin-right: 8px;
            fill: $color-white;
        }

        &__btn {
            margin: 0;

            &:first-child {
                margin-right: 20px;
                background-color: #B53A9A;
                border-color: #B53A9A;
                color: $color-white;

                // A retirer quand le client aura créé son compte instragram
                display: none;

                &:hover {
                    background-color: $color-bg--transparent;
                    color: $color-text;

                    & svg {
                        fill:$color-text;
                    }
                }
            }

            &:last-child {
                background-color: #405995;
                border-color: #405995;
                color: $color-white;

                &:hover {
                    background-color: $color-bg--transparent;
                    color: $color-text;

                    & svg {
                        fill:$color-text;
                    }
                }
            }
        }
    }

    &__title {
        color: $color-text;
        margin: 0!important;
        display: inline-block;
        padding: 0;
        font-size: 2.188rem;
        line-height: 2.375rem;
        font-weight: $font-weight-light;
        box-shadow: unset;
        text-transform: uppercase;

        & span {
            display: flex;
            font-size: 3.125rem;
            line-height: 3.438rem;
            font-weight: $font-weight-extrabold;
            text-transform: none;
            
            & svg {
                width: 29px;
                height: 49px;
                margin-left: 10px;
                fill: $color-fourth;
            }
        }

        &:before, &:after {
            content: none;
        }
    }

    &__container__sw {
        position: relative;
        z-index: 0;

        &::before {
            content: "";
            position: absolute;
            width: 170px;
            height: 170px;
            top: 155px;
            left: -70px;
            background-color: $color-fourth;
            border-radius: $border-radius--round;
        }

        #ff-stream-1 {
            height: 410px!important;
            min-height: unset!important;
            padding: 0!important;
            background: transparent!important;

            .ff-header {
                display: none!important;
            }

            .ff-stream-wrapper {
                width: 1230px!important;
                margin: 0!important;
                margin-left: -30px!important;
                padding: 0!important;
            }

            .ff-item {
                border-radius: 8px!important;
                overflow: hidden!important;
                filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08))!important;

                .ff-content {
                    max-height: 120px!important;
                    overflow: hidden!important;
                    margin: 10px 15px!important;
                    height: 120px!important;
                }

                .ff-item-meta {
                    margin: 0 15px!important;
                }

                &.ff-instagram .ff-label-wrapper .ff-icon:before {
                    background: #B53A9A!important;
                }

                &.ff-facebook .ff-label-wrapper .ff-icon:before {
                    background: #405995!important;
                }
            }

            .ff-item-cont {
                height: 370px!important;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .ff-img-holder {
                    min-height: 245px!important;
                    height: 245px!important;
                    width: 100%!important;

                    img {
                        min-height: 245px!important;
                        height: 245px!important;
                        object-fit: cover!important;

                    }
                }

                a {
                    color: $color-text!important;
                }

                .ff-label-wrapper {

                    .ff-icon::before {
                        width: 50px!important;
                        height: 50px!important;
                        top: -15px!important;
                        right: -15px!important;
                        border-width: 0!important;
                        border-radius: 50%!important;
                    }
                }
            }

            .ff-loadmore-wrapper {
                display: none!important;
            }
            
            .ff-item-bar::before {
                border-color: $color-text!important;
            }

            .ff-item-bar a:hover i::after {
                content: none!important;
            }

            .ff-item-bar a, .ff-item-bar a i {
                color: $color-text!important;
            }

            .ff-item-bar a:hover, .ff-item-bar a:hover i {
                color: $color-second!important;
            }

            .ff-item-bar .ff-share-wrapper .ff-icon-share {
                color: $color-text!important;
                background-color: transparent !important;

                &:hover {
                    color: $color-second!important;
                    background-color: transparent !important;
                }
            }

            .ff-upic-round .picture-item__inner {
                border-radius: 0!important;
            }

            .ff-upic-round .ff-img-holder:first-child, .ff-upic-round .ff-img-holder:first-child img {
                border-radius: 0!important;
            }
        }
    }
}


//======================================================================================================
// 7 - Annuaire
//======================================================================================================
.annuaire {

    &__section {
        position: relative;
        z-index: 0;
        height: 550px;
        width: calc(50% + 115px);
        background: url('/wp-content/themes/saulx-les-chartreux/assets/src/images/Annuaire.jpg');
        background-size: cover;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(11, 38, 69, 0.6), rgba(11, 38, 69, 0.6));
        }
    }

    &__heading {
        width: 700px;
        margin-top: 140px;
        margin-left: auto;
    }

    &__title {
        color: $color-white;
        display: inline-block;
        padding: 0;
        margin: 0 0 30px 0;
        font-size: 2.188rem;
        line-height: 2.375rem;
        font-weight: $font-weight-light;
        box-shadow: unset;
        text-transform: uppercase;

        & span {
            display: flex;
            font-size: 3.125rem;
            line-height: 3.438rem;
            font-weight: $font-weight-extrabold;
            text-transform: none;
            
            & svg {
                width: 29px;
                height: 49px;
                margin-left: 10px;
                fill: $color-second;
            }
        }

        &:before, &:after {
            content: none;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 585px;
        margin-left: auto;
        margin-right: 115px;
    }

    &__button {
        margin: 0 20px 20px 0;

        & svg {
            margin-right: 8px;
        }
    }
}


//======================================================================================================
// 8 - Nous situer
//======================================================================================================
.nous-situer {
    
    &__section {
        height: 550px;
        width: calc(50% - 115px);
        background: url('/wp-content/themes/saulx-les-chartreux/assets/src/images/Carte.jpg');
    }

    &__title {
        display: inline-block;
        padding: 0;
        margin: 0 0 30px 0;
        font-size: 3.125rem;
        line-height: 3.438rem;
        font-weight: $font-weight-extrabold;
        color: $color-text;

        &__span {
            display: flex;
            font-weight: $font-weight-light;
            
            & svg {
                width: 29px;
                height: 49px;
                margin-left: 10px;
                fill: $color-white;
            }
        }

        &:before, &::after {
            content: none;
        }
    }

    &__heading {
        width: 470px;
        margin-top: 140px;
        margin-left: 70px;
        margin-right: auto;
        display: flex;
        flex-direction: column;

        &__btn {
            width: fit-content;
            margin: 0;

            & svg {
                margin-right: 8px;
            }
        }
    }
}


//======================================================================================================
// 9 - Partenaires
//======================================================================================================
.partenaire {

    &__section {
        position: relative;
        margin: 80px 0;
    }

    &__list {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__item {

        &:nth-child(1) {
            & a {
                 border-color: rgba(254, 15, 8, .15);
            }
        }

        &:nth-child(2) {
            & a {
                 border-color: rgba(0, 169, 212, .15);
            }
        }

        &:nth-child(3) {
            & a {
                 border-color:rgba(255, 210, 0, .15);
            }
        }

        &:nth-child(4) {
            & a {
                 border-color: rgba(152, 193, 15, .15);
            }
        }

        &:nth-child(5) {
            & a {
                 border-color: rgba(213, 46, 128, .15);
            }
        }

        &:hover {

            & a {
                box-shadow: 1px 1px 40px rgba(14, 53, 49, 0.1);
            }

            &:nth-child(1) {
                & a {
                     border-color: #FE0F08;
                }
            }
    
            &:nth-child(2) {
                & a {
                     border-color: #00A9D4;
                }
            }
    
            &:nth-child(3) {
                & a {
                     border-color:#FFD200;
                }
            }
    
            &:nth-child(4) {
                & a {
                     border-color: #98C10F;
                }
            }
    
            &:nth-child(5) {
                & a {
                    border-color:  #d52e80;
                }
            }
        }
    }

    &__link {
        display: inline-block;
        border-width: 5px;
        border-style: solid;
        border-radius: 120px;
        width: 218px;
        height: 145px;
        transition: all $duration ease-in-out;

        & img {
            width: 208px;
            height: 135px;
            border-radius: 120px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .front-page {

        &__section {

            &--1 {
                &:before {
                    width: calc(50% - 10px);
                }
            }
        }
    }

    // ALU
    .home-slideshow {

        .slideshow {
            height: 614px;
            width: calc( 50% + 170px );
        }

        &__links {

            &__list {
                width: 300px;
                height: 220px;
                top: -330px;
                left: 10px;

                &:before {
                    width: 275px;
                    height: 275px;
                    left: -155px;
                    bottom: -255px;
                }
            }

            &__item {
                font-size: 1.25rem;

                &:nth-child(2) {
                    margin: 50px 0;
                }

                & svg {
                    margin-right: 10px;

                    &:nth-child(2) {
                        margin-right: 0;
                        margin-left: 2px;
                    }
                }
            }
        }
    }

    // Post
    .post {

        &__section {
            height: 795px;
            margin-top: 70px;

            &:before {
                height: 500px;
            }

            .swiper-container {
                width: 1030px;
                overflow: unset;
            }
        }

        &__container {
            //gap: 20px;

            .featured-post {

                &--big {
                    width: 460px;
                    height: 320px;
                    margin-bottom: 30px;

                    .post {

                        &__content {
                            width: 460px;
                            height: 320px;
                        }
                    }
                }

                &--small {
                    width: 220px;

                    .post {

                        &__content {
                            width: 220px;
                            height: 155px;
                        }
                    }
                }
            }

            &:before {
                top: 85px;
                left: -80px;
            }
        }
    }

    // Access
    .access {

        &__section {
            width: 460px;
            margin: 70px 0;

            &:before {
                right: -105px;
                top: -180px;
            }
        }

        &__list {
            gap: 20px;
        }

        &__link {
            width: 140px;
        }

        &__info {
            padding: 0 0 15px;
        }
    }


    // Demarches
    .demarches {

        &__section {
            width: 465px;
            padding-left: 60px;
            margin: 70px 0;
        }

        &__content {
            &__title {
                & svg {
                    margin-right: 10px;
                }
            }
        }
    }

    // Events
    .events {

        &__section {
            padding-top: 70px;
            height: 740px;

            .swiper-container {
                width: 820px;

                .swiper-wrapper {
                    .swiper-slide {
                        width: 400px;
                        margin-right: 20px;
                    }
                }
            }
        }

        &__content {
            height: 320px;
        }

        &__date {
            font-size: 1.25rem;
            line-height: 1.5rem;
        }

        &__infos {
            &__title {
                font-size: 1.625rem;
            }
        }

        &__navigation {
            width: 900px;
            height: 320px;

            &__btn {
                top: 120px;
            }
        }
    }


    // Social
    .social {

        &__section {

            &:before {
                transform: translateX(330px);
            }
        }
        
        &__heading {
            &__btn  {
                font-size: 0;
                width: 60px;
                height: 60px;
                padding: 0;

                & svg {
                    margin-right: 0;
                }
            }
        }

        &__container__sw {

            #ff-stream-1 {
                height: 410px!important;

                .ff-stream-wrapper {
                    width: 980px!important;
                    height: 410px!important;
                    margin: 0 0 0 -20px!important;
                }
            }
        }
    }


    // Annuaire
    .annuaire {

        &__section {
            width: calc(50% + 90px);
            height: unset;
            min-height: 550px;
        }

        &__heading, &__list {
            width: 490px;
            margin-left: 140px;
            margin-right: 0;
        }

        &__button {
            margin: 0 10px 20px 0;
        }
    }


    // Nous situer
    .nous-situer {

        &__section {
            width: calc(50% - 90px);
            background-position-x: -70px;
            height: auto;
        }

        &__heading {
            margin-left: 50px;
            width: 305px;
        }
    }


    // Partenaires
    .partenaire {

        &__section {
            margin: 70px 0;
        }
        
        &__list {
            gap: 20px 30px;
        }

        &__link {
            width: 220px;
            height: 145px;

            & img {
                width: 210px;
                height: 135px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .front-page {
        &__section {
            .container {
                display: block;
            }

            &--1 {
                height: 1265px;

                &:before {
                    height: 610px;
                    width: 100%;
                    top: unset;
                    bottom: 0;
                }
            }

            &--2 {
                display: block;

                &:before {
                    content: none;
                }
            }
        }
    }

    // ALU
    .home-slideshow {
        display: flex;
        flex-direction: column;

        .slideshow {
            width: 100%;
            height: 555px;
            order: 1;

            &__navigation {
                right: 10px;
                bottom: 40px;
                height: 50px;
                width: 50px;

                &__btn {
                    width: 25px;
                    height: 50px;
                }
            }
        }

        &__links {

            &__container {
                background-color: $color-main;
                max-width: unset;
                padding: 0;
                width: 100%;
                height: 80px;
                margin: -170px auto 90px;
            }

            &__list {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                position: unset;
                width: 100%;
                height: 80px;
                max-width: 640px;
                padding: 0 10px;
                margin: 0 auto;
                
                &:before {
                    content: none;
                }

                & button {
                    display: none;
                }
            }

            &__item {
                font-size: 1rem;
                line-height: 1.375rem;
                margin: 0 0 0 40px;

                &:nth-child(2) {
                    margin-top: 0;
                    margin-bottom: 0;
                }
 
                & svg {
                    &:nth-child(2) {
                        display: none;
                    }
                }
            }
        }
    }

    
    // Posts
    .post {

        &__section {
            position: relative;
            margin-top: 50px;
            height: 1230px;

            &:before {
                height: 900px;
            }

            .swiper-container {
                width: 1030px;
                overflow: unset;
            }
        }

        &__heading {
            margin-bottom: 30px;

            &__btn {
                position: absolute;
                bottom: 60px;
            }
        }

        &__container {
            max-height: 990px;
            //gap: 30px 20px;

            .featured-post {

                &--big {
                    width: 300px;
                    height: 320px;
                    background-color: $color-dark;
                    margin-bottom: 30px;

                    .post {

                        &__content {
                            width: 300px;
                            height: 210px;

                            & img {
                                height: 210px;
                            }
                        }

                        &__info {
                            position: absolute;
                            height: auto;
                            bottom: 0;
                            padding: 0 20px 20px;
                        }
                    }
                }

                &--small {
                    width: 300px;
                    height: auto;
                    max-height: 300px;
                    margin-bottom: 20px;

                    .post {
                        
                        &__content {
                            width: 300px;
                            height: 210px;

                            & img {
                                height: 210px;
                            }
                        }
                    }
                }
            }

            &:before, &:after {
                content: none;
            }
        }
    }

    // Access
    .access {

        &__section {
            width: 100%;
            margin: 0;
            padding: 50px 0;
            height: 655px;

            &:before {
                content: none;
            }
        }

        &__list {
            gap: 25px;
        }

        &__link {
            width: 190px;
        }

        &__info {
            padding: 0 10px 15px;
        }
    }

    // Demarches
    .demarches {

        &__section {
            width: 100%;
            margin: 0;
            padding: 50px 0;
            height: 610px;
        }
    }

    // Events
    .events {

        &__section {
            position: relative;
            padding-top: 50px;

            .swiper-container {
                width: 620px;
                height: 410px;

                .swiper-wrapper {
                    .swiper-slide {
                        width: 300px;
                    }
                }
            }
        }

        &__heading {
            margin: 0 0 30px;

            &__btn {
                position: absolute;
                bottom: 50px;
            }
        }

        &__content {
            height: 240px;
        }

        &__navigation {
            height: 240px;
            width: 700px;
            left: -40px;
            right: -40px;

            &__btn {
                top: 90px;
            }
        }

        &__infos {
            margin-top: 15px;
        }
    }

    // Social
    .social {

        &__section {
            position: relative;
            height: 630px;
            margin: 55px 0;
            
            &:before {
                content: none;
            }
        }

        &__buttons {
            position: absolute;
            bottom: 0;
        }

        &__container__sw {
            
            &:before {
                content: none;
            }

            #ff-stream-1 {
                height: 430px!important;

                .ff-stream-wrapper {
                    width: 660px!important;
                    height: 430px!important;
                    overflow: hidden!important;

                    .ff-item {
                        filter: drop-shadow(1px 1px 10px rgba(14, 53, 49, 0.1))!important;
                    }
                }
            }
        }
    }

    // Annuaire
    .annuaire {
        
        &__section {
            display: inline-block;
            width: 100%;
        }

        &__heading {
            width: 620px;
            margin: 140px auto 0;
        }

        &__list {
            width: 620px;
            margin: 0 auto;
        }
    }

    // Nous situer
    .nous-situer {

        &__section {
            display: inline-block;
            width: 100%;
            height: 490px;
            background-position-x: 0;
            background-position-y: -50px;
        }

        &__heading {
            width: 620px;
            margin: 120px auto 0;
        }
    }

    // Partenaires
    .partenaire {

        &__section {
            margin: 50px 0;
        }

        &__list {
            gap: 20px;
        }

        &__link {
            width: 190px;
            height: 125px;

            & img {
                width: 180px;
                height: 115px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .front-page {

        &__section {
            
            &--1 {
                height: 1475px;

                &:before {
                    height: 640px;
                }
            }
        }

        .home__title, section .home__title {
            font-size: 1.25rem;
            line-height: 1.375rem;
            margin-bottom: 20px;

            &__span {
                font-size: 2.5rem;
                line-height: 2.813rem;

                & svg {
                    margin-top: -10px;
                }
            }
        }
    }

    // ALU
    .home-slideshow {
        .slideshow {
            height: 345px;
            
            &__content {
                left: 30px;
                right: 30px;
                width: auto;
            }
        }

        &__links {
            &__list {
                max-width: 320px;
                padding: 0 25px;
                justify-content: space-between;
            }

            &__item {
                font-size: 0.75rem;
                line-height: 1rem;
                width: 110px;
                margin: 0;

                & svg {
                    min-width: 40px;
                }

                &:first-child {
                    max-width: 124px;
                }
            }
        }
    }

    // Post
    .post {

        &__section {
            height: auto;
            padding-bottom: 170px;

            &:before {
                height: 1520px;
            }
        }

        &__container {
            max-height: unset;
        }

        &__heading {
            margin-bottom: 20px;
        }
    }

    // Access
    .access {

        &__section {
            height: 835px;
        }

        &__list {
            gap: 20px;
        }

        &__link {
            width: 140px;
        }

        &__info {
            padding: 0 0 15px;
        }
    }

    // Demarche
    .demarches {
        
        &__section {
            height: 640px;
        }

        &__container {
            height: 345px;
        }

        &__content {
            &__title {
                & svg {
                    min-width: 30px;
                }
            }
        }
    }

    // Events
    .events {

        &__title {
            font-size: 1.25rem;
            line-height: 1.375rem;
            margin-bottom: 20px;

            & span {
                font-size: 2.5rem;
                line-height: 2.813rem;

                & svg {
                    margin-top: -10px;
                }
            }
        }

        &__section {
            height: 730px;

            .swiper-container {
                width: 300px;

                .swiper-wrapper .swiper-slide.swiper-slide-next {
                    opacity: .3;
                }
            }
        }

        &__navigation {
            width: 380px;
        }
    }

    // Social
    .social {
        &__section {
            height: 1040px;
        }

        &__title {
            font-size: 1.25rem;
            line-height: 1.375rem;
            margin-bottom: 20px;

            & span {
                font-size: 2.5rem;
                line-height: 2.813rem;
                position: relative;

                & svg {
                    position: absolute;
                    right: -35px;
                    margin-left: unset;
                    margin-top: -10px;
                }
            }
        }

        &__container__sw {

            #ff-stream-1 {
                height: 860px!important;
    
                .ff-stream-wrapper {
                    width: 340px!important;
                    height: 860px!important;
                }
            }
        }

    }

    // Annuaire
    .annuaire {

        &__title {
            font-size: 1.25rem;
            line-height: 1.375rem;
            margin-bottom: 20px;

            & span {
                font-size: 2.5rem;
                line-height: 2.813rem;

                & svg {
                    margin-top: -10px;
                }
            }
        }

        &__section {
            height: 515px;
        }

        &__heading {
            width: 300px;
            margin-top: 50px;
        }

        &__list {
            width: 300px;
        }

        &__button {
            padding: 15px 20px;
            margin: 0 0 20px 0;
        }
    }

    // Nous situer
    .nous-situer {

        &__section {
            background-position-y: -65px;
        }

        &__heading {
            width: 300px;
        }

        &__title {
            line-height: 2.813rem;
        }
    }

    // Partenaire
    .partenaire {

        &__section {
            margin: 40px 0;
        }

        &__list {
            flex-wrap: wrap;
            gap: 20px;
        }

        &__link {
            width: 140px;
            height: 102px;

            & img {
                width: 130px;
                height: 92px;
            }
        }
    }
}

// 320
@media screen and (max-width: 320px) {
    .nous-situer {
        &__section {
            background-position-x: -150px;
        }
    }
}
