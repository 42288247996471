.breadcrumb {
    background-color: $color-bg--neutral;

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        position: relative;
        display: flex;
        align-items: center;
        color: $color-main;
        padding: 0 22px;

        // "Vous êtes ici" style
        //&:first-child {
        //    font-size: $font-size--text-small;
        //    font-weight: $font-weight-bold;
        //    text-transform: uppercase;
        //}

        &:first-child {
            padding-left: 0;
        }

        & a:hover {
            color: $color-gray;
        }

        & svg {
            position: absolute;
            right: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
            fill: $color-main;
            width: 25px;
            height: 25px;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 40px;
            width: 100%;
            height: 1px;
        }

        ul {
            min-height: 40px;
        }

        li {
            min-height: 40px;
            padding: 0 40px 0 0;

            & svg {
                right: 0;
            }
        } 
    }

}
