header {
    position: relative;
    width: 100%;
    z-index: 15;
    max-height: 80px;
}

.alerte {

    &__section {
        position: relative;
        z-index: 20;
        background-color: $color-third;
        transition: all $duration ease-in-out;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 80px;

        
    }

    &:hover {
        & .alerte__content {
            & svg {
                margin-right: 25px;
            }
        }
    }

    &__content {
        display: flex;
        align-items: center;
        
        & svg {
            margin-right: 18px;
            transition: all $duration ease-in-out;
        }

        &__title {
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: $font-weight-normal;
            font-family: $font-family--heading;
            color: $color-text;
            margin: 0;
            padding: 0;

            &:before, &:after {
                content: none;
            }
        }

        &__label {
            display: flex;
            align-items: center;
            font-size: 1.75rem;
            line-height: 1.875rem;
            margin-right: 18px;
            color: $color-text;
            font-weight: $font-weight-bold;
            font-family: $font-family--heading;
            transition: all $duration ease-in-out;
        }
    }

    &__read {
        font-size: .875rem;
        line-height: 1rem;
        color: $color-text;
        opacity: 1;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        transition: all $duration ease-in-out;

        &:hover {
            opacity: .6;
        }
    }
}

.header-top {
    position: relative;
    width: 100%;
    pointer-events: none;

    .container--identity {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 0;

        .site-identity {
            display: flex;
            align-items: center;
        }

        .identity {
            &__title {
                display: block;
                position: relative;
                z-index: -1;
                font-size: 0;
                line-height: 0;
                margin: 0;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    bottom: -65px;
                    left: 50%;
                    width: 560px;
                    height: 560px;
                    transform: translateX(-50%);
                    border-radius: $border-radius--round;
                    background-color: $color-white;
                }

                .sticky-link {
                    display: none;
                }
            }
        }
    }

    &--left, &--right {
        pointer-events: auto;
    }

    &--left {
        z-index: -2;
        margin-top: 40px;
        -webkit-transform: translateZ(-1px);
        transform: translateZ(-1px);
    }

    &--right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 855px;
        height: 80px;
        margin-top: 50px;
        padding: 20px 30px;
        border-radius: $border-radius;
        background-color: $color-second;
        box-shadow: 1px 1px 40px rgba(14, 53, 49, 0.1);
    }
}

.header--fixed .header-top {
    position: fixed;
    top: 0;
    margin-top: 0;
    background-color: $color-second;

    &--left {
        margin-top: 0;
        display: flex;
        margin-right: 190px;
    }

    &--right {
        margin-top: 0;
        width: 100%;
        padding: 0;
        box-shadow: unset;
        background-color: $color-bg--transparent;

        #nav-main .nav-main-item .sub-menu {
            top: unset;
            padding: 45px 0;
        }
    }

    .container--identity {
        height: 80px;

        .identity {
            &__title {
                
                & svg {
                    display: none;
                }

                & svg.sticky-link {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: $color-white;
                    background-color: $color-main;
                    border-radius: $border-radius--round;
                    transition: all $duration ease-in-out;
                }

                &:before {
                    content: none;
                }
            }

            &:hover {
                .identity__title {
                    & svg.sticky-link {
                        fill: $color-main;
                        background-color: $color-second;
                    }
                }
            }
        }
    }

    .tools {
        &__search {
            display: block;
            margin-right: 15px;
            background-color: $color-white!important;

            & svg {
                fill: $color-text;
            }

            &:hover {
                background-color: $color-second!important;
            }
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &__content {
            & > svg {
                fill: $color-bg--neutral;
            }
        }
        
        &::before {
            background: $color-bg--neutral;
        }
    }
}

.tools {

    .container--tools {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        background: $color-bg--transparent;

        & > svg {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            fill: $color-white; 
        }
    }

    .tool {
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        background-color: $color-main;
        border: 0;
        transition: all $duration ease-in-out;
        
        svg {
            fill: $color-white;
            transition: all $duration;
        }

        &:hover {
            cursor: pointer;
            background-color: $color-second;

            svg {
                fill: $color-main;
            }
        }
    }

    &__accessibility {
        display: flex;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    &__search {
        display: none;

        svg {
            width: 40px;
            height: 40px;
        }

        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }
}



//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    pointer-events: auto!important;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .header-top {

        .container--identity {
            .identity {
                &__title {

                    & svg {
                        width: 164px;
                        height: 148px;
                    }

                    &:before {
                        width: 440px;
                        height: 440px;
                        bottom: -45px;
                    }
                }
            }
        }

        &--right {
            padding: 15px;
            width: 700px;
            height: 70px;
        }

        &--left {
            -webkit-transform: translateZ(-1px);
            transform: translateZ(-1px);
        }
    }

    .header--fixed .header-top {
        &--left {
            margin-right: 100px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .header {
        max-height: unset;
        pointer-events: none;
    }

    .header-top {
        position: unset;
        height: 90px;
        margin: 80px 0 0 0;
        background-color: $color-second;

        .container--identity {
            position: relative;

            .identity {
                &__title {
                    & svg {
                        width: 132px;
                        height: 117px;
                    }

                    &:before {
                        width: 330px;
                        height: 330px;
                        bottom: -193px;
                        border-radius: 0;
                        clip-path: ellipse(50% 55% at 50% 0);
                    }
                }
            }
        }

        &--left {
            position: absolute;
            z-index: 0;
            margin-top: 20px;
        }

        &--right {
            width: 100%;
            height: 90px;
            margin: 0;
            padding: 0;
            justify-content: flex-end;
            background-color: unset;
            box-shadow: unset;
        }
        
    }

    .tools {
        
        &__search {
            display: unset;
            margin-right: 10px!important;
        }
    }

    .alerte {
        pointer-events: auto;

        &__item {
            flex-direction: column;
            height: auto;
            padding: 20px;
        }

        &__content {
            flex-direction: column;

            &__title {
                text-align: center;
                margin: 5px 0 10px 0;
            }
        }
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    .header-top {

        .container--identity {
            .identity {
                &__title {
                    & svg {
                        width: 95px;
                        height: 83px;
                    }

                    &:before {
                        width: 235px;
                        height: 235px;
                        bottom: -142px;
                        transform: translateX(-58%);
                    }
                }
            }
        }

        &--left {
            margin-top: 10px;
        }
    }
    
}
