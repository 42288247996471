.footer {
    background-color: $color-main;
    overflow: hidden;

    &__container {
        
        position: relative;
        z-index: 0;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: -105px;
            left: -95px;
            width: 210px;
            height: 210px;
            border: 5px solid $color-white;
            border-radius: $border-radius--round;
            opacity: .1;
        }
    }

    &__titles {
        color: $color-white;
        font-size: 1.375rem;
        font-weight: $font-weight-bold;
        margin: 0 0 20px;
    }

    &__infos {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 90px 0 0 0;
        background: $color-main;
        color: $color-white;
        
        &__coordonnees {
            width: 300px;
            margin-right: 100px;
        }

        &__horaires {
            width: 380px;
        }

        &__title {
            font-size: 1.375rem;
            line-height: 1.5rem;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            margin-bottom: 20px;
        }

        &__text {
            font-size: 1rem;
            line-height: 1.25rem;
            margin-bottom: 10px;
        }

        &__phone {
            font-weight: $font-weight-bold;

            &:hover, &:focus {
                color: $color-second;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 0 60px;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-right: 80px;
        }

        li {
            position: relative;
            padding: 0 15px;

            a {
                font-size: 0.875rem;
                line-height: 1.25rem;
                opacity: .6;
                color: $color-white;
                transition: all $duration ease-in-out;

                &:hover {
                    opacity: 1;
                    color: $color-second;
                }
            }
        }

        .button {
            margin: 0;
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {

        &__infos {
            &__title {
                font-size: 1.25rem;
                line-height: 1.5rem;
            }
        }

        &__menu {
            ul {
                width: 540px;
                margin-right: 20px;
                justify-content: flex-start;

                li {
                    padding-bottom: 15px;
                }
            }
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {

        &__container {
            &:before {
                content: none;
            }
        }

        &__infos {

            &__coordonnees {
                width: 275px;
                margin-right: 45px;
            }

            &__horaires {
                width: 300px;
            }
        }

        &__menu {
            padding-bottom: 30px;

            ul {
                width: 355px;
                margin: 0;
            }
        }
    }

}

// 640
@media screen and (max-width: $small) {

    .footer {
        
        &__infos {
            flex-direction: column;

            &__coordonnees {
                width: 100%;
                margin-bottom: 40px;
            }
        }

        &__menu {
            flex-direction: column;
            align-items: flex-start;

            & .button {
                margin-bottom: 50px;
            }

            ul {
                width: 100%;

                li {
                    padding: 0 20px 15px;

                    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                        padding-left: 0;
                    }

                    &:nth-child(4) {
                        padding-right: 0;
                    }

                    &:nth-child(5) {
                        padding-left: 0;
                    }

                    &:nth-child(6) {
                        padding-right: 0;
                    }
                }
            }
        }
    }

}